<template>
    <div class="dataPreview">
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
            >
                >
                <el-table-column
                    :label="column.info_name"
                    :prop="column.data_name"
                    v-for="(column, index) in tableClums"
                    :key="index"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row[column.data_name] }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            tableClums: [],
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 15,
                total: 0,
                pagesizes: [15, 30, 45, 60, 100],
            },
        };
    },
    watch: {
        entityId(val) {
            if (val) {
                this.entityId = val;
                this.getTableClums();
            }
        },
    },
    computed: {},
    methods: {
        // 获取表头
        getTableClums() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/data/browse/items/' + this.entityId)
                .then(res => {
                    if (res) {
                        this.tableClums = res;
                        if (this.tableClums.length > 0 ) {
                            this.getTableData();
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取分页数据
        getTableData() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/data/browse/getpage/' + this.entityId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getTableData();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getTableData();
        },
    },
    created() {
        if (!this.entityId) {
            // this.$message.warning('请先选择树节点');
            return;
        }
        this.getTableClums();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.dataPreview
    height 100%
    .table-con
        padding .2rem
        height 100%
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
    .data-table
        padding .1rem
        border-bottom 1px solid #E7E7E7
        .el-form-item
            margin-bottom 0
</style>